module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Shipper Express Tracking","short_name":"starter","start_url":"/","background_color":"#9EE96A","display":"minimal-ui","icon":"src/images/favicon-01.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cc2b6eb504abf73d6ecae56c0342f4a3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["ar","en","fr","th"],"defaultLanguage":"ar","siteUrl":"https://tracking.shipper.express/","redirect":false,"i18nextOptions":{"lowerCaseLng":true,"saveMissing":false,"debug":true,"fallbackLng":"ar","supportedLngs":["ar","en","fr","th"],"defaultNS":"common","redirect":false,"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false},"pages":[]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com"],"web":[{"name":"Noto Kufi Arabic","file":"https://fonts.googleapis.com/css?family=Noto Kufi Arabic"},{"name":"Poppins","file":"https://fonts.googleapis.com/css?family=Poppins"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
